import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Navbar from "./component/Navbar";
import CustomerRequests from "./component/CustomerRequests";
import SearchWarranty from "./component/SearchWarranty";
import CustomerDetails from "./component/Customer_details";
import PairButton from "./component/PairButton";
import Dispatch from "./component/Dispatch";
import AdminLogin from "./component/AdminLogin";
import { ExpirationDateProvider } from "./component/ExpirationDateContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import Reports from "./component/Reports";
import Instockwarranty from "./component/Instockwarranty";

// Constants for restricted users
const RESTRICTED_USERS = ['abdul', 'ra', 'hassan'];

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");

  // Utility function to check if user is restricted
  const isRestrictedUser = (username) => {
    return RESTRICTED_USERS.includes(username?.toLowerCase());
  };

  const handleAdminLogin = (username, role) => {
    const isRestricted = isRestrictedUser(username);
    setIsLoggedIn(true);
    setUsername(username);
    setUserRole(isRestricted ? 'restricted' : role);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("username", username);
    localStorage.setItem("role", isRestricted ? 'restricted' : role);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername("");
    setUserRole("");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("username");
    localStorage.removeItem("role");
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    const storedUsername = localStorage.getItem("username");
    const storedRole = localStorage.getItem("role");

    if (loggedIn === "true" && storedUsername) {
      const isRestricted = isRestrictedUser(storedUsername); 
      setIsLoggedIn(true);
      setUsername(storedUsername);
      setUserRole(isRestricted ? 'restricted' : storedRole);
    }
  }, []);

  // Custom Route component for restricted access
  const RestrictedRoute = ({ component: Component, path, ...rest }) => {
    const currentUser = localStorage.getItem("username");
    const isRestricted = isRestrictedUser(currentUser);
    const storedRole = localStorage.getItem("role");

    return (
      <Route
        path={path}
        {...rest}
        render={props => {
          if (!localStorage.getItem("isLoggedIn")) {
            return <Redirect to="/" />;
          }

          if (isRestricted && path !== '/instock') {
            return <Redirect to="/instock" />;
          }

          return (
            <Component
              {...props}
              username={currentUser}
              userRole={storedRole}
            />
          );
        }}
      />
    );
  };

  return (
    <ExpirationDateProvider>
      <Router>
        <div className="app-container">
          <Navbar 
            isLoggedIn={isLoggedIn} 
            handleLogout={handleLogout} 
            username={username}
            userRole={userRole}
            isRestricted={isRestrictedUser(username)}
          />
          <Switch>
            {/* Login Route */}
            <Route
              exact
              path="/"
              render={() =>
                isLoggedIn ? (
                  isRestrictedUser(username) ? (
                    <Redirect to="/instock" />
                  ) : (
                    <Redirect to="/customer_requests" />
                  )
                ) : (
                  <AdminLogin onAdminLogin={handleAdminLogin} />
                )
              }
            />

            {/* Instock Route - Accessible by all users */}
            <RestrictedRoute
              path="/instock"
              component={Instockwarranty}
            />

            {/* Routes only accessible by non-restricted users */}
            {!isRestrictedUser(username) && (
              <>
                <RestrictedRoute
                  path="/customer_details/:claimId"
                  component={CustomerDetails}
                />
                <RestrictedRoute
                  path="/dispatch_track_schedule/:claimId"
                  component={Dispatch}
                />
                <RestrictedRoute
                  path="/PairButton/:claimId"
                  component={PairButton}
                />
                <RestrictedRoute
                  path="/SearchWarranty"
                  component={SearchWarranty}
                />
                <RestrictedRoute
                  path="/customer_requests"
                  component={CustomerRequests}
                />
                <RestrictedRoute
                  path="/generate_reports"
                  component={Reports}
                />
              </>
            )}

            {/* Catch-all redirect */}
            <Redirect to="/" />
          </Switch>
        </div>
      </Router>
    </ExpirationDateProvider>
  );
}

export default App;