import React from "react";
import { Link, useHistory } from "react-router-dom";
import awsLogoImage from "../images/logo/awslogo1.png";

const Navbar = ({ isLoggedIn, handleLogout, username, isRestricted }) => {
  const navItemStyle = {
    margin: "0 10px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  };

  const history = useHistory();

  const handleLogoClick = (e) => {
    e.preventDefault();
    history.push("/");
    window.location.reload();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
       
        <a href="/" className="navbar-brand" onClick={handleLogoClick}>
          <img src={awsLogoImage} alt="awslogo" style={{ height: "70px" }} />
        </a>
     
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
          <ul className="navbar-nav text-center align-items-center">
            {isLoggedIn && (
              <>
                <li className="nav-item" style={navItemStyle}>
                  <span className="navbar-text text-dark">
                    User: {username}
                  </span>
                </li>

                {isRestricted ? (
                  // Restricted users only see File stock Claim
                  <li className="nav-item" style={navItemStyle}>
                    <Link className="nav-link text-dark" to="/instock">
                      File stock Claim
                    </Link>
                  </li>
                ) : (
                  // Non-restricted users see all options
                  <>
                    <li className="nav-item" style={navItemStyle}>
                      <Link className="nav-link text-dark" to="/customer_requests">
                        Customer Claims
                      </Link>
                    </li>
                    <li className="nav-item" style={navItemStyle}>
                      <Link className="nav-link text-dark" to="/generate_reports">
                        Generate Reports
                      </Link>
                    </li>
                    <li className="nav-item" style={navItemStyle}>
                      <Link className="nav-link text-dark" to="/SearchWarranty">
                        Search Warranties
                      </Link>
                    </li>
                    <li className="nav-item" style={navItemStyle}>
                      <Link className="nav-link text-dark" to="/instock">
                        File stock Claim
                      </Link>
                    </li>
                  </>
                )}

                <li className="nav-item" style={navItemStyle}>
                  <Link className="nav-link text-dark" to="/" onClick={handleLogout}>
                    Log Out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};


export default Navbar;