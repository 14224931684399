import React, { useState } from 'react';
import axios from 'axios';

const Instockwarranty = () => {
  const [formData, setFormData] = useState({
    first_name: 'Stock Claim',
    last_name: '',
    address: '418 Harding Industrial Drive',
    city: 'Nashville',
    state: 'TN',
    zipcode: '37211',
    email: 'mmmunis1406@gmail.com',
    phone_number: '5519988968',
    brand: '',
    model_number: '',
    serial_number: '',
    problemDescription: '',
  });

  const [claimId, setClaimId] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const styles = {
    container: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#f9fafb',
      padding: '20px',
      paddingTop: '80px',
    },
    formWrapper: {
      maxWidth: '1000px',
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      padding: '20px',
    },
    header: {
      textAlign: 'center',
      color: '#4f46e5',
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '24px',
    },
    formGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '16px',
      marginBottom: '16px',
    },
    inputGroup: {
      marginBottom: '16px',
    },
    label: {
      display: 'block',
      fontWeight: '500',
      marginBottom: '8px',
    },
    input: {
      width: '100%',
      padding: '8px',
      border: '1px solid #d1d5db',
      borderRadius: '6px',
      backgroundColor: '#fff',
    },
    readOnlyInput: {
      width: '100%',
      padding: '8px',
      border: '1px solid #d1d5db',
      borderRadius: '6px',
      backgroundColor: '#f3f4f6',
    },
    textarea: {
      width: '100%',
      padding: '8px',
      border: '1px solid #d1d5db',
      borderRadius: '6px',
      height: '128px',
      resize: 'none',
    },
    button: {
      width: '100%',
      padding: '12px',
      backgroundColor: '#4f46e5',
      color: 'white',
      border: 'none',
      borderRadius: '6px',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    messageBox: {
      position: 'fixed',
      top: '20px',
      right: '20px',
      backgroundColor: 'green',
      color: 'white',
      padding: '16px',
      borderRadius: '8px',
      boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
      zIndex: 1000,
    },
    closeButton: {
      backgroundColor: 'transparent',
      color: 'white',
      border: 'none',
      fontWeight: 'bold',
      cursor: 'pointer',
      marginLeft: '16px',
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      if (name === 'serial_number') {
        updatedData.last_name = `${value}`;
      }
      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Trim all input fields and validate
    const trimmedData = {
      ...formData,
      brand: formData.brand.trim(),
      model_number: formData.model_number.trim(),
      serial_number: formData.serial_number.trim(),
      problemDescription: formData.problemDescription.trim(),
    };
  
    // Check if required fields are empty (even after trimming)
    if (!trimmedData.brand || !trimmedData.model_number || !trimmedData.serial_number || !trimmedData.problemDescription) {
      alert("Please fill in all required fields correctly.");
      return; // Stop form submission
    }
  
    setIsSubmitting(true); // Disable the button
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/create-customer-request/`,
        trimmedData
      );
  
      if (response.status === 201) {
        setClaimId(response.data.data.id);
        setShowMessage(true);
  
        // Refresh page after 3 seconds
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        console.error("Error:", response.data.message);
        setIsSubmitting(false); // Re-enable button in case of error
      }
    } catch (error) {
      console.error("Error:", error);
      setIsSubmitting(false); // Re-enable button in case of error
    }
  };

  
  return (
    <div style={styles.container}>
      {showMessage && (
        <div style={styles.messageBox}>
          The claim has been filed and the claim id is {claimId}
          <button style={styles.closeButton} onClick={() => setShowMessage(false)}>
            Close
          </button>
        </div>
      )}

      <div style={styles.formWrapper}>
        <h2 style={styles.header}>Stock Claim</h2>
        <form onSubmit={handleSubmit}>
          <div style={styles.formGrid}>
            <div style={styles.inputGroup}>
              <label style={styles.label}>First Name</label>
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Last Name</label>
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Phone Number</label>
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Zipcode</label>
              <input
                type="text"
                name="zipcode"
                value={formData.zipcode}
                readOnly
                style={styles.readOnlyInput}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Brand</label>
              <input
                type="text"
                name="brand"
                value={formData.brand}
                onChange={handleChange}
                placeholder="Enter Brand"
                style={styles.input}
                required
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Model Number</label>
              <input
                type="text"
                name="model_number"
                value={formData.model_number}
                onChange={handleChange}
                placeholder="Enter Model Number"
                style={styles.input}
                required
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Serial Number</label>
              <input
                type="text"
                name="serial_number"
                value={formData.serial_number}
                onChange={handleChange}
                placeholder="Enter Serial Number"
                style={styles.input}
                required
              />
            </div>
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>Part Needed</label>
            <textarea
              name="problemDescription"
              value={formData.problemDescription}
              onChange={handleChange}
              placeholder="Describe the problem"
              style={styles.textarea}
              required
            />
          </div>

          <button type="submit" style={styles.button} disabled={isSubmitting}>
  {isSubmitting ? "Submitting..." : "Submit"}
</button>

        </form>
      </div>
    </div>
  );
};

export default Instockwarranty;
